import { createRouterSelector, getSelectors } from '@ngrx/router-store';

export const selectRouterState = createRouterSelector();
export const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl, // select the current url
  selectCurrentRoute,
  selectFragment,
} = getSelectors(selectRouterState);
