import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PagingParams } from './models';

@Injectable({ providedIn: 'root' })
export class QueryParamsService {
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  public get queryParams(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }

  public get queryParamsSnapshot(): Params {
    return this.activatedRoute.snapshot.queryParams;
  }

  handleQueryParams(params: PagingParams & Params = {}): void {
    this.router.navigate([], {
      queryParams: { ...params, timestamp: new Date().getTime() },
      queryParamsHandling: 'merge',
      relativeTo: this.activatedRoute,
    });
  }

  serializeParams(params: Params, prefix?: string): string {
    const query = Object.keys(params || {}).map((key) => {
      const value = params[key];

      if (params.constructor === Array) key = `${prefix}[]`;
      else if (params.constructor === Object)
        key = prefix ? `${prefix}[${key}]` : key;

      if (typeof value === 'object') return this.serializeParams(value, key);
      else return `${key}=${encodeURIComponent(value)}`;
    });

    return [...query].join('&');
  }
}
