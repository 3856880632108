import {
  DefaultProjectorFn,
  MemoizedSelector,
  createFeatureSelector,
  createSelector,
} from '@ngrx/store';
import { State, carritoFeatureKey } from './carrito.reducer';
import { CarritoNQItem, CarritoQItem } from '../models';

function sumAmounts(prev: number, next: CarritoNQItem | CarritoQItem): number {
  return prev + next.monto;
}

export const selectCarritoState =
  createFeatureSelector<State>(carritoFeatureKey);
export const selectNumberOfItemsInCarrito = createSelector(
  selectCarritoState,
  (state: State) => state.nq.length + state.q.length,
);

export const selectCarritoTotalAmount = createSelector(
  selectCarritoState,
  ({ nq, q }: State) => nq.reduce(sumAmounts, 0) + q.reduce(sumAmounts, 0),
);

export const selectMesIsInCarrito = (
  mesId: number,
): MemoizedSelector<object, boolean, DefaultProjectorFn<boolean>> =>
  createSelector(
    selectCarritoState,
    (state: State) => !!state.nq.find((m) => m.id_mes === mesId),
  );
