export const calculatePaginationWhenAddingAnItem = (
  totalNumberOfItems: number,
  itemsPerPage: number,
  numberOfItemsAdded = 1,
): number => {
  const totalPages = Math.ceil(totalNumberOfItems / itemsPerPage);
  let nextPage = totalPages;
  const nextTotalPages = Math.ceil(
    (totalNumberOfItems + numberOfItemsAdded) / itemsPerPage,
  );
  if (nextTotalPages > totalPages) {
    nextPage += 1;
  }
  return nextPage;
};

export const calculatePaginationWhenRemovingAnItem = (
  currentPage: number,
  totalNumberOfItems: number,
  itemsPerPage: number,
  numberOfItemsRemoved = 1,
): number => {
  let nextPage = currentPage;
  const totalPages = Math.ceil(totalNumberOfItems / itemsPerPage);
  const nextTotalPages = Math.ceil(
    (totalNumberOfItems - numberOfItemsRemoved) / itemsPerPage,
  );
  const currentPageIsTheLastPage = currentPage === totalPages;
  if (currentPageIsTheLastPage && nextTotalPages < totalPages) {
    nextPage -= 1;
  }
  return nextPage;
};
