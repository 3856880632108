import { createAction, props } from '@ngrx/store';
import { FirstLoginPayload, LoginPayload, LoginResponse } from '../models';

export const Login = createAction(
  '[Auth] Login',
  props<{ payload: LoginPayload }>(),
);
export const LoginSuccess = createAction(
  '[Auth] Login Success',
  props<{ loginSuccess: LoginResponse }>(),
);
export const LoginFailure = createAction(
  '[Auth] Login Failure',
  props<{ error: unknown }>(),
);

export const SetNewPasswordForFirstLogin = createAction(
  '[Auth] Set new password for first login',
  props<{ payload: FirstLoginPayload }>(),
);
export const SetNewPasswordForFirstLoginSuccess = createAction(
  '[Auth] Set new password for first login success',
);
export const SetNewPasswordForFirstLoginFailure = createAction(
  '[Auth] Set new password for first login failure',
  props<{ error: unknown }>(),
);

export const Logout = createAction('[Auth] Logout');
export const LogoutSuccess = createAction('[Auth] Logout success');
export const LogoutFailure = createAction(
  '[Auth] Logout failure',
  props<{ error: unknown }>(),
);
