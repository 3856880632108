<div (click)="onContainerClick($event)" class="file-input-container">
  <input
    (blur)="onFocusOut($event)"
    (focus)="onFocusIn($event)"
    [accept]="accept"
    [formControl]="filesControl"
    [multiple]="multiple"
    [required]="required"
    #fileInput
    type="file"
  />
</div>
