import {
  Action,
  ActionReducer,
  ActionReducerMap,
  MetaReducer,
} from '@ngrx/store';
import { RouterReducerState, routerReducer } from '@ngrx/router-store';
import { RouterStateUrl } from '../shared/utils/router';
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from 'src/environments/environment';
import { localStorageSync } from 'ngrx-store-localstorage';

import { routerFeatureKey } from './router';
import * as authStore from '../auth/store';
import * as carritoStore from '../features/carrito/store';
export interface AppState {
  [routerFeatureKey]: RouterReducerState<RouterStateUrl>;
  [authStore.authFeatureKey]: authStore.State;
  [carritoStore.carritoFeatureKey]: carritoStore.State;
}

export const reducers: ActionReducerMap<AppState> = {
  [routerFeatureKey]: routerReducer,
  [authStore.authFeatureKey]: authStore.reducer,
  [carritoStore.carritoFeatureKey]: carritoStore.reducer,
};

export function logger(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState | undefined> {
  return function (
    state: AppState | undefined,
    action: Action,
  ): AppState | undefined {
    return reducer(state, action);
  };
}

export function localStorageSyncReducer(
  reducer: ActionReducer<AppState>,
): ActionReducer<AppState> {
  return localStorageSync({
    keys: [authStore.authFeatureKey, carritoStore.carritoFeatureKey],
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer[] = !environment.production
  ? [logger, storeFreeze, localStorageSyncReducer]
  : [localStorageSyncReducer];

export const APP_EFFECTS = [authStore.AuthEffects, carritoStore.CarritoEffects];
