import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, tap } from 'rxjs';
import {
  ChangeOfPasswordPayload,
  ChangeOfPasswordResponse,
  FirstLoginPayload,
  LoginPayload,
  LoginResponse,
  SendResetLinkEmailResponse,
} from '../models';
import endpoints from './endpoints';
import { ToastService } from '@core/services';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public apiUrl = environment.apiUrl;
  constructor(private _http: HttpClient, private toastService: ToastService) {}

  login(payload: LoginPayload): Observable<LoginResponse> {
    return this._http.post<LoginResponse>(endpoints.login, payload);
  }

  sendResetLinkEmail(email: string): Observable<SendResetLinkEmailResponse> {
    return this._http.post<SendResetLinkEmailResponse>(
      endpoints.sendResetLinkEmail,
      {
        email,
      },
    );
  }

  newPassword(user: FirstLoginPayload): Observable<unknown> {
    return this._http.post(endpoints.newPassword, user);
  }

  changePassword(
    payload: ChangeOfPasswordPayload,
  ): Observable<ChangeOfPasswordResponse> {
    return this._http
      .post<ChangeOfPasswordResponse>(endpoints.changeOfPassword, payload)
      .pipe(
        tap(() =>
          this.toastService.showSuccess(
            'La contraseña se ha cambiado con éxito.',
          ),
        ),
      );
  }
}
