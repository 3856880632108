import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from './custom-material/custom-material.module';
import { DndDirective } from './directives/dnd/dnd.directive';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ExtendedDirective } from './directives/extended/extended.directive';
import { MatTableResponsiveDirective } from './directives/mat-table-responsive/mat-table-responsive.directive';
import { ConfirmDialogModule } from './components/confirm-dialog/confirm-dialog.module';
import { TableToolbarModule } from './components/table-toolbar/table-toolbar.module';
import { CustomLoadingModule } from './components/custom-loading/custom-loading.module';
import { IconOrSpinnerModule } from './components/icon-or-spinner/icon-or-spinner.module';
import { InputWithConfirmationModule } from './components/input-with-confirmation/input-with-confirmation.module';
import { ThemeSwitcherModule } from './components/theme-switcher/theme-switcher.module';
import { PipesModule } from './pipes/pipes.module';
import { PageHeaderModule } from './components/page-header/page-header.module';
import { FileInputModule } from './components/file-input/file-input.module';
import { LangSelectorModule } from './components/lang-selector/lang-selector.module';

@NgModule({
  declarations: [DndDirective, ExtendedDirective, MatTableResponsiveDirective],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    CustomMaterialModule,
    AngularEditorModule,
  ],
  exports: [
    AngularEditorModule,
    ConfirmDialogModule,
    CustomLoadingModule,
    CustomMaterialModule,
    PageHeaderModule,
    DndDirective,
    ExtendedDirective,
    IconOrSpinnerModule,
    InputWithConfirmationModule,
    MatTableResponsiveDirective,
    PipesModule,
    MatTableResponsiveDirective,
    ReactiveFormsModule,
    TableToolbarModule,
    ThemeSwitcherModule,
    FileInputModule,
    LangSelectorModule,
  ],
})
export class SharedModule {}
