import { createAction, props } from '@ngrx/store';
import { CarritoNQItem, CarritoQItem } from '../models';

export const AddTratamientoNoQuirurgicoToCarrito = createAction(
  '[Carrito] Add tratamiento no quirurgico to carrito',
  props<{ item: CarritoNQItem }>(),
);

export const AddTratamientoQuirurgicoToCarrito = createAction(
  '[Carrito] Add tratamiento quirurgico to carrito',
  props<{ item: CarritoQItem }>(),
);

export const RemoveTratamientoQuirurgicoFromCarrito = createAction(
  '[Carrito] Remove tratamiento quirurgico from carrito',
  props<{ id_tratamiento: number }>(),
);

export const RemoveTratamientoNoQuirurgicoFromCarrito = createAction(
  '[Carrito] Remove tratamiento no quirurgico from carrito',
  props<{ id_mes: number }>(),
);

export const CleanCarrito = createAction('[Carrito] Clean');
