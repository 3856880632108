<h1 mat-dialog-title>Filtros avanzados</h1>
<div class="py-3" mat-dialog-content>
  <ng-container *ngIf="advancedFiltersTemplate">
    <ng-container *ngTemplateOutlet="advancedFiltersTemplate"></ng-container>
  </ng-container>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close>{{ 'GENERAL.CANCEL' | translate }}</button>
  <button mat-button (click)="onClear()">Limpiar filtros</button>
  <button mat-button (click)="onApply()">Filtrar</button>
</div>
