import { createFeatureSelector, createSelector } from '@ngrx/store';
import { authFeatureKey, State } from './auth.reducer';

export const selectAuthState = createFeatureSelector<State>(authFeatureKey);
export const selectIsAuthenticated = createSelector(
  selectAuthState,
  (state: State) => !!state.user,
);

export const selectIsPrimerInicio = createSelector(
  selectAuthState,
  (state: State) => !!state.user?.primer_inicio,
);

export const selectAuthUser = createSelector(
  selectAuthState,
  (state: State) => state.user,
);

export const selectUpdatingUser = createSelector(
  selectAuthState,
  (state: State) => state.updatingUser,
);
export const selectToken = createSelector(
  selectAuthState,
  (state: State) => state.token,
);

export const selectError = createSelector(
  selectAuthState,
  (state: State) => state.error,
);
