<header class="grid" [ngClass]="'justify-content-' + justifyContent">
  <form *ngIf="useGlobalFilter" class="col-12 xl:col-4" autocomplete="false" (ngSubmit)="applyGlobalFilter()" #formRef="ngForm">
    <mat-form-field appearance="fill" class="w-full">
      <mat-label>Buscar</mat-label>
      <input [(ngModel)]="globalFilter.value" type="text" [name]="globalFilter.name" matInput />
      <button *ngIf="globalFilter.value" (click)="clearGlobalFilter()" mat-icon-button type="button" matSuffix>
        <mat-icon>close</mat-icon>
      </button>
      <button mat-icon-button type="submit" matSuffix>
        <mat-icon> search </mat-icon>
      </button>
    </mat-form-field>
  </form>
  <section class="col-12 xl:col-fixed text-left w-12 md:w-auto">
    <button
      *ngIf="showDownloadExcelButton"
      (click)="downloadExcel.emit($event)"
      class="excel-button"
      matTooltip="Descargar hojas de cálculos"
      mat-flat-button
      [disabled]="loading"
    >
      <mat-icon class="mr-1 md:mr-0">backup_table</mat-icon>
      <span class="md:hidden">Descargar hoja de cálculos</span>
    </button>
    <button
      *ngIf="showAdvancedFiltersButton && advancedFilters"
      (click)="openAdvancedFilters()"
      color="accent"
      mat-flat-button
      matTooltip="Filtros avanzados"
      [disabled]="loading"
    >
      <mat-icon class="mr-1 md:mr-0" *ngIf="advancedFiltersButtonIcon">{{ advancedFiltersButtonIcon }}</mat-icon>
      <span [ngClass]="{ 'md:hidden': advancedFiltersButtonIcon }">Filtros</span>
    </button>
    <button
      *ngIf="showAddButton"
      (click)="add.emit($event)"
      color="primary"
      mat-flat-button
      [disabled]="loading || disabledAddButton"
    >
      <mat-icon class="mr-1" *ngIf="addButtonIcon">{{ addButtonIcon }}</mat-icon>
      {{ addButonText | translate }}
    </button>
  </section>
</header>
