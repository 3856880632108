import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlErrorMessagePipe } from './control-error-message.pipe';
import { CapitalizePipe } from './capitalize.pipe';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [ControlErrorMessagePipe, CapitalizePipe],
  imports: [CommonModule, TranslateModule],
  exports: [ControlErrorMessagePipe, CapitalizePipe],
})
export class PipesModule {}
