import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

export declare type ToastSeverity = 'error' | 'warning' | 'info' | 'success';
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private snackBarDefaultConfig: MatSnackBarConfig = {
    verticalPosition: 'bottom',
    horizontalPosition: 'right',
    duration: 3000,
    panelClass: ['severity-container'],
  };

  constructor(private snackbar: MatSnackBar, private ngZone: NgZone) {}

  showSuccess(
    message: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(message, 'success', action, config);
  }

  showInfo(
    message: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(message, 'info', action, config);
  }

  showWarn(
    message: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(message, 'warning', action, config);
  }

  showError(
    message: string,
    action?: string,
    config: MatSnackBarConfig = {},
  ): void {
    this.showToast(message, 'error', action, config);
  }

  showToast(
    message: string,
    severity: ToastSeverity,
    action = 'Ok',
    config: MatSnackBarConfig = {},
  ): void {
    this.ngZone.run(() => {
      this.snackbar.open(message, action, {
        ...this.snackBarDefaultConfig,
        ...config,
        panelClass: [
          ...(this.snackBarDefaultConfig.panelClass || []),
          'severity-' + severity,
        ],
      });
    });
  }
}
