import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomLoadingComponent } from './custom-loading.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';

@NgModule({
  declarations: [CustomLoadingComponent],
  imports: [
    CommonModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.cubeGrid,
      backdropBackgroundColour: 'rgba(231, 231, 231, 0.58);',
      backdropBorderRadius: '4px',
      primaryColour: '#d64a27',
      secondaryColour: '#d64a27',
      tertiaryColour: '#d64a27',
    }),
  ],
  exports: [CustomLoadingComponent],
})
export class CustomLoadingModule {}
