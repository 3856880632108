import { Component, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { LoadingService } from '@core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-custom-loading',
  templateUrl: './custom-loading.component.html',
  styleUrls: ['./custom-loading.component.css'],
})
export class CustomLoadingComponent implements AfterViewInit {
  public loading$: Observable<boolean> = this.loading.isLoading$;
  constructor(public loading: LoadingService, private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
