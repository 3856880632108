import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LangSelectorComponent } from './lang-selector.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [LangSelectorComponent],
  imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule],
  exports: [LangSelectorComponent],
})
export class LangSelectorModule {}
