import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogData } from './confirm-dialog.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements Required<ConfirmDialogData> {
  private readonly defaultOptions: Required<ConfirmDialogData> = {
    title: '¿Está seguro?',
    cancelButtonText: 'No',
    confirmButtonText: 'Sí',
    actionDescription: '',
  };
  public cancelButtonText: string;
  public title: string;
  public confirmButtonText: string;
  public actionDescription: string;
  constructor(@Inject(MAT_DIALOG_DATA) data?: ConfirmDialogData) {
    this.title = data?.title || this.defaultOptions.title;
    this.cancelButtonText =
      data?.cancelButtonText || this.defaultOptions.cancelButtonText;
    this.confirmButtonText =
      data?.confirmButtonText || this.defaultOptions.confirmButtonText;
    this.actionDescription =
      data?.actionDescription || this.defaultOptions.actionDescription;
  }
}
